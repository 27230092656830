const Admin = () =>
    import ('../components/admin/Admin')
const AdminMenu = () =>
    import ('../components/admin/AdminMenu')
const AllDishes = () =>
    import ('../components/admin/dishes/AllDishes')
const AdminDishesStats = () =>
    import ('../components/admin/dishes/AdminDishesStats')
const AdminAlcoholStats = () =>
    import ('../components/admin/dishes/AdminAlcoholStats')
const AdminPlasticStats = () =>
    import ('../components/admin/dishes/AdminPlasticStats')
const AdminDishesStatsAddress = () =>
    import ('../components/admin/dishes/AdminDishesStatsAddress')
const Dish = () =>
    import ('../components/admin/dishes/Dish')
const CreateDish = () =>
    import ('../components/admin/dishes/CreateDish')
const UpdateDish = () =>
    import ('../components/admin/dishes/UpdateDish')
const CreateCategory = () =>
    import ('../components/admin/dishes/CreateCategory')
const UpdateCategory = () =>
    import ('../components/admin/dishes/UpdateCategory')
const AdminUsers = () =>
    import ('../components/admin/users/AdminUsers')
const AdminUser = () =>
    import ('../components/admin/users/AdminUser')
const ChangeUserEmail = () =>
    import ('../components/admin/users/ChangeUserEmail')
const ChangeUserPassword = () =>
    import ('../components/admin/users/ChangeUserPassword')
const AdminSettings = () =>
    import ('../components/admin/settings/AdminSettings')
const RestaurantInfo = () =>
    import ('../components/admin/settings/RestaurantInfo')
const AdminOpenHours = () =>
    import ('../components/admin/settings/AdminOpenHours')
const EditContents = () =>
    import ('../components/admin/settings/EditContents')
const AdminEditFreeFood = () =>
    import ('../components/admin/settings/AdminEditFreeFood')
const AdminEditFreeAddons = () =>
    import ('../components/admin/settings/AdminEditFreeAddons')
const AdminSpecialMenus = () =>
    import ('../components/admin/special_menus/AdminSpecialMenus')
const CreateSpecialMenu = () =>
    import ('../components/admin/special_menus/CreateSpecialMenu')
const EditSpecialMenu = () =>
    import ('../components/admin/special_menus/EditSpecialMenu')
const AdminDisabledDates = () =>
    import ('../components/admin/disabled_dates/AdminDisabledDates')
const CreateDisabledDate = () =>
    import ('../components/admin/disabled_dates/CreateDisabledDate')
const EditDisabledDate = () =>
    import ('../components/admin/disabled_dates/EditDisabledDate')
const AdminDeliveries = () =>
    import ('../components/admin/deliveries/AdminDeliveries')
const CreateDelivery = () =>
    import ('../components/admin/deliveries/CreateDelivery')
const EditDelivery = () =>
    import ('../components/admin/deliveries/EditDelivery')
const AdminFreeDeliveryAreas = () =>
    import ('../components/admin/deliveries/AdminFreeDeliveryAreas')
const AdminFreeDeliveryAreaForm = () =>
    import ('../components/admin/deliveries/AdminFreeDeliveryAreaForm')
const AdminSoupOfTheDays = () =>
    import ('../components/admin/soup_of_the_day/AdminSoupOfTheDays')
const EditSoupOfTheDay = () =>
    import ('../components/admin/soup_of_the_day/EditSoupOfTheDay')
const AdminRevenues = () =>
    import ('../components/admin/revenue/AdminRevenues')
const AdminRevenue = () =>
    import ('../components/admin/revenue/AdminRevenue')
const AdminCreateRevenue = () =>
    import ('../components/admin/revenue/AdminCreateRevenue')
const AdminPromocodes = () =>
    import ('../components/admin/promocodes/AdminPromocodes')
const AdminCreatePromocode = () =>
    import ('../components/admin/promocodes/AdminCreatePromocode')
const AdminInvoices = () =>
    import ('../components/admin/invoices/AdminInvoices')
const AdminCreateInvoice = () =>
    import ('../components/admin/invoices/AdminCreateInvoice')
const AdminUpdateInvoice = () =>
    import ('../components/admin/invoices/AdminUpdateInvoice')
const AdminInvoice = () =>
    import ('../components/admin/invoices/AdminInvoice')
const AdminAddMores = () =>
    import ('../components/admin/add_mores/AdminAddMores')
const CreateAddMore = () =>
    import ('../components/admin/add_mores/CreateAddMore')
const UpdateAddMore = () =>
    import ('../components/admin/add_mores/UpdateAddMore')
const StaffOrders = () =>
    import ('../components/staff/orders/StaffOrders')
const AdminOrderChanges = () =>
    import ('../components/admin/orders/OrderChanges')
const StaffTables = () =>
    import ('../components/staff/orders/StaffTables')
const UberStorePause = () =>
    import ('../components/admin/uber/UberStorePause')
const UberOrders = () =>
    import ('../components/admin/uber/UberOrders')
const GlovoMenu = () =>
    import ('../components/admin/glovo/GlovoMenu')
const WoltMenu = () =>
    import ('../components/admin/wolt/WoltMenu')
// # TODO: BLOCK WOLT ORDERS
const WoltOrders = () =>
    import ('../components/admin/wolt/WoltOrders')
const GlovoStorePause = () =>
    import ('../components/admin/glovo/GlovoStorePause')
const AdminHappyHoursImg = () =>
    import ('../components/admin/settings/AdminHappyHoursImg')
const AdminNewCustomReceipt = () =>
    import ('../components/admin/receipts/AdminNewCustomReceipt')
const AdminCustomReceipts = () =>
    import ('../components/admin/receipts/AdminCustomReceipts')
const AdminCustomReceipt = () =>
    import ('../components/admin/receipts/AdminCustomReceipt')

export default {
    path: '/admin',
    component: Admin,
    children: [{
            path: '',
            name: 'Admin',
            component: AdminMenu,
            meta: { onlyStaff: true }
        },
        // Menu
        {
            path: 'dishes',
            name: 'AdminDishes',
            component: AllDishes,
        },
        {
            path: 'dishes/:id',
            name: 'AdminDish',
            component: Dish,
            meta: { onlyAdmin: true }
        },
        {
            path: 'dishes/new',
            name: 'AdminCreateDish',
            component: CreateDish,
            meta: { onlyAdmin: true }
        },
        {
            path: 'dishes-stats',
            name: 'AdminDishesStats',
            component: AdminDishesStats,
            meta: { onlyAdmin: true }
        },
        {
            path: 'dishes-stats-address',
            name: 'AdminDishesStatsAddress',
            component: AdminDishesStatsAddress,
            meta: { onlyAdmin: true }
        },
        {
            path: 'alcohol-stats',
            name: 'AdminAlcoholStats',
            component: AdminAlcoholStats,
            meta: { onlyAdmin: true }
        },
        {
            path: 'plastic-stats',
            name: 'AdminPlasticStats',
            component: AdminPlasticStats,
            meta: { onlyAdmin: true }
        },
        {
            path: 'dishes/:id/edit',
            name: 'AdminUpdateDish',
            component: UpdateDish,
            meta: { onlyAdmin: true }
        },
        {
            path: 'category/new',
            name: 'AdminCreateCategory',
            component: CreateCategory,
            meta: { onlyAdmin: true }
        },
        {
            path: 'category/:id/edit',
            name: 'AdminUpdateCategory',
            component: UpdateCategory,
            meta: { onlyAdmin: true }
        },
        // Users
        {
            path: 'uzytkownicy',
            name: 'AdminUsers',
            component: AdminUsers,
            meta: { onlyAdmin: true }
        },
        {
            path: 'uzytkownicy/:id',
            name: 'AdminUser',
            component: AdminUser,
            meta: { onlyAdmin: true }
        },
        {
            path: 'uzytkownicy/:id/email',
            name: 'AdminChangeUserEmail',
            component: ChangeUserEmail,
            meta: { onlyAdmin: true }
        },
        {
            path: 'uzytkownicy/:id/pwd',
            name: 'AdminChangeUserPassword',
            component: ChangeUserPassword,
            meta: { onlyAdmin: true }
        },
        // Settings
        {
            path: 'settings',
            name: 'AdminSettings',
            component: AdminSettings,
            meta: { onlyAdmin: true }
        },
        {
            path: 'settings/restaurant',
            name: 'AdminRestaurantInfo',
            component: RestaurantInfo,
            meta: { onlyAdmin: true }
        },
        {
            path: 'settings/open-hours',
            name: 'AdminOpenHours',
            component: AdminOpenHours,
            meta: { onlyAdmin: true }
        },
        // SpecialMenus
        {
            path: 'settings/special_menus',
            name: 'AdminSpecialMenus',
            component: AdminSpecialMenus,
            meta: { onlyAdmin: true }
        },
        {
            path: 'settings/special_menu/new',
            name: 'AdminCreateSpecialMenu',
            component: CreateSpecialMenu,
            meta: { onlyAdmin: true }
        },
        {
            path: 'settings/special_menu/:id',
            name: 'AdminEditSpecialMenu',
            component: EditSpecialMenu,
            meta: { onlyAdmin: true }
        },
        // DisabledDates
        {
            path: 'settings/disabled_dates',
            name: 'AdminDisabledDates',
            component: AdminDisabledDates,
            meta: { onlyAdmin: true }
        },
        {
            path: 'settings/disabled_date/new',
            name: 'AdminCreateDisabledDate',
            component: CreateDisabledDate,
            meta: { onlyAdmin: true }
        },
        {
            path: 'settings/disabled_date/:id',
            name: 'AdminEditDisabledDate',
            component: EditDisabledDate,
            meta: { onlyAdmin: true }
        },
        // Deliveries
        {
            path: 'settings/deliveries',
            name: 'AdminDeliveries',
            component: AdminDeliveries,
            meta: { onlyAdmin: true }
        },
        {
            path: 'settings/delivery/new',
            name: 'AdminCreateDelivery',
            component: CreateDelivery,
            meta: { onlyAdmin: true }
        },
        {
            path: 'settings/delivery/:id',
            name: 'AdminEditDelivery',
            component: EditDelivery,
            meta: { onlyAdmin: true }
        },
        // Free Delivery Areas
        {
            path: 'settings/free_delivery_area',
            name: 'AdminFreeDeliveryAreas',
            component: AdminFreeDeliveryAreas,
            meta: { onlyAdmin: true }
        },
        {
            path: 'settings/free_delivery_area/new',
            name: 'AdminCreateFreeDeliveryArea',
            component: AdminFreeDeliveryAreaForm,
            meta: { onlyAdmin: true, form: 'create' }
        },
        {
            path: 'settings/free_delivery_area/:id',
            name: 'AdminEditFreeDeliveryArea',
            component: AdminFreeDeliveryAreaForm,
            meta: { onlyAdmin: true, form: 'edit' }
        },
        // Soup Of The Day
        {
            path: 'settings/soup_of_the_days',
            name: 'AdminSoupOfTheDays',
            component: AdminSoupOfTheDays,
            meta: { onlyAdmin: true }
        },
        {
            path: 'settings/soup_of_the_day/:id',
            name: 'AdminEditSoupOfTheDay',
            component: EditSoupOfTheDay,
            meta: { onlyAdmin: true }
        },
        // Contents
        {
            path: 'settings/contents',
            name: 'AdminEditContents',
            component: EditContents,
            meta: { onlyAdmin: true }
        },
        // Free Food
        {
            path: 'settings/free-food',
            name: 'AdminEditFreeFood',
            component: AdminEditFreeFood,
            meta: { onlyStaff: true }
        },
        // Free Addons
        {
            path: 'settings/free-addons',
            name: 'AdminEditFreeAddons',
            component: AdminEditFreeAddons,
            meta: { onlyStaff: true }
        },
        // Revenue
        {
            path: 'revenues',
            name: 'AdminRevenues',
            component: AdminRevenues,
            meta: { onlyStaff: true }
        },
        {
            path: 'revenue/:id',
            name: 'AdminRevenue',
            component: AdminRevenue,
            meta: { onlyStaff: true }
        },
        {
            path: 'revenues/new',
            name: 'AdminCreateRevenue',
            component: AdminCreateRevenue,
            meta: { onlyStaff: true }
        },
        // Promocodes
        {
            path: 'promocodes',
            name: 'AdminPromocodes',
            component: AdminPromocodes,
            meta: { onlyAdmin: true }
        },
        {
            path: 'promocodes/new',
            name: 'AdminCreatePromocode',
            component: AdminCreatePromocode,
            meta: { onlyAdmin: true }
        },
        // Invoices
        {
            path: 'invoices',
            name: 'AdminInvoices',
            component: AdminInvoices,
            meta: { onlyStaff: true }
        },
        {
            path: 'invoices/new',
            name: 'AdminCreateInvoice',
            component: AdminCreateInvoice,
            meta: { onlyStaff: true }
        },
        {
            path: 'invoices/:id',
            name: 'AdminInvoice',
            component: AdminInvoice,
            meta: { onlyStaff: true, noLayout: true }
        },
        {
            path: 'invoices/:id/edit',
            name: 'AdminUpdateInvoice',
            component: AdminUpdateInvoice,
            meta: { onlyStaff: true }
        },
        // Orders
        {
            path: 'orders',
            name: 'AdminOrders',
            component: StaffOrders,
            meta: { onlyStaff: true, staffOrders: 'history' }
        },
        {
            path: 'canceledOrders',
            name: 'AdminCanceledOrders',
            component: StaffOrders,
            meta: { onlyAdmin: true, staffOrders: 'canceled' }
        },
        {
            path: 'order_changes',
            name: 'AdminOrderChanges',
            component: AdminOrderChanges,
            meta: { onlyAdmin: true }
        },
        {
            path: 'tables',
            name: 'StaffTables',
            component: StaffTables,
            meta: { onlyStaff: true }
        },
        // Add mores
        {
            path: 'add_mores',
            name: 'AdminAddMores',
            component: AdminAddMores,
            meta: { onlyAdmin: true }
        },
        {
            path: 'add_more/new',
            name: 'CreateAddMore',
            component: CreateAddMore,
            meta: { onlyAdmin: true }
        },
        {
            path: 'add_more/:id/edit',
            name: 'UpdateAddMore',
            component: UpdateAddMore,
            meta: { onlyAdmin: true }
        },
        // UBER
        {
            path: 'uber_pause',
            name: 'UberStorePause',
            component: UberStorePause,
            meta: { onlyStaff: true }
        },
        {
            path: 'uber_orders',
            name: 'UberOrders',
            component: UberOrders,
            meta: { onlyAdmin: true }
        },
        // GLOVO
        {
            path: 'glovo_menu',
            name: 'GlovoMenu',
            component: GlovoMenu,
            meta: { onlyAdmin: true }
        },
        {
            path: 'glovo_pause',
            name: 'GlovoStorePause',
            component: GlovoStorePause,
            meta: { onlyStaff: true }
        },
        // WOLT
        {
            path: 'wolt_menu',
            name: 'WoltMenu',
            component: WoltMenu,
            meta: { onlyAdmin: true, restaurant: 'theramen' }
        },
        {
            path: 'wolt_orders',
            name: 'WoltOrders',
            component: WoltOrders,
            meta: { onlyAdmin: true, restaurant: 'theramen' }
        },
        {
            path: 'wolt_menu/hanami',
            name: 'WoltDeliveryMenu',
            component: WoltMenu,
            meta: { onlyAdmin: true, restaurant: 'hanami_ramen' }
        },
        {
            path: 'wolt_orders/hanami',
            name: 'WoltDeliveryOrders',
            component: WoltOrders,
            meta: { onlyAdmin: true, restaurant: 'hanami_ramen' }
        },
        {
            path: 'wolt_menu/iraka',
            name: 'WoltIrakaMenu',
            component: WoltMenu,
            meta: { onlyAdmin: true, restaurant: 'iraka_sushi' }
        },
        {
            path: 'wolt_orders/iraka',
            name: 'WoltIrakaOrders',
            component: WoltOrders,
            meta: { onlyAdmin: true, restaurant: 'iraka_sushi' }
        },
        // HH
        {
            path: 'happy_hours',
            name: 'AdminHappyHoursImg',
            component: AdminHappyHoursImg,
            meta: { onlyAdmin: true }
        },
        // Custom receipts
        {
            path: 'new_custom_receipt',
            name: 'AdminNewCustomReceipt',
            component: AdminNewCustomReceipt,
            meta: { onlyAdmin: true }
        },
        {
            path: 'custom_receipts',
            name: 'AdminCustomReceipts',
            component: AdminCustomReceipts,
            meta: { onlyAdmin: true }
        },
        {
            path: 'custom_receipt/:uid',
            name: 'AdminCustomReceipt',
            component: AdminCustomReceipt,
            meta: { onlyAdmin: true }
        },
    ]
}